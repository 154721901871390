import { mapActions, mapGetters } from 'vuex';

import _ from 'lodash';
import { isNullOrWhiteSpace, countryArray, provinceArray } from '@/utils';
import { Sidebar, LatLongMap } from '@/components';

import isServer from '@/utils/isServer';

if (!isServer) {
  const { required, email } = require('vee-validate/dist/rules');
  const { extend } = require('vee-validate');
  var { VueEditor } = require('vue2-editor/dist/vue2-editor.core');
  var VueSlider = require('vue-slider-component');
  require('vue-slider-component/theme/default.css');
  var VueTagsInput = require('@johmun/vue-tags-input').default;

  extend('required', {
    ...required,
    message: 'Dit veld is vereist.',
  });

  extend('email', {
    ...email,
    message: 'Een geldig e-mailadres is vereist.',
  });

  extend('coordinate', {
    message: 'Geef een geldig coördinaat in (Bv: xx.xxxxxx)',
    validate: (value) => {
      const strongRegex = new RegExp(/^(\+|-)?(?:90(?:(?:\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,6})?))$/);
      return strongRegex.test(value);
    },
  });

  extend('dates', {
    message: 'De einddatum van de activiteit kan niet kleiner zijn dan de begindatum.',
    params: ['target'],
    validate(value, { target }) {
      return value < target;
    },
  });

  extend('price', {
    message: 'Een prijs kan enkel tussen 0 en 1500 euro liggen.',
    validate: (value) => value >= 0 && value <= 1500,
  });

  extend('url', {
    // eslint-disable-next-line no-unused-vars
    message: 'Url kan geen "/", "?" of "." bevatten.',
    validate: (value) => !value.includes('?') && !value.includes('/') && !value.includes('.'),
  });

  extend('discount', {
    params: ['price'],
    message: 'De korting kan niet lager dan nul zijn en niet hoger zijn dan de prijs van de activiteit.',
    validate: (value, { price }) => {
      const discount = parseInt(value, 10);
      const eventPrice = parseInt(price, 10);

      return discount >= 0 && discount <= eventPrice;
    },
  });
  var ImageUpload = require('vue-image-crop-upload').default;
}

export default {
  metaInfo: {
    title: 'Event formpage',
  },
  name: 'EventForm',
  components: {
    [Sidebar.name]: Sidebar,
    VueTagsInput,
    VueEditor,
    VueSlider,
    LatLongMap,
    ImageUpload,
  },
  props: {},
  data: () => ({
    clubId: null,
    eventId: null,
    eventUrl: null,
    editMode: false,
    event: {
      name: '',
      from: new Date().toISOString(),
      until: new Date((new Date()).valueOf() + 1000 * 3600 * 24).toISOString(),
      fromAge: 0,
      toAge: 100,
      price: 0,
      requiredLicenseNumber: false,
      isPrivate: false,
      registrationPossibleUntil: null,
      disciplineIds: [],
    },
    tags: [],
    currentTag: '',
    selectedDisciplines: [],
    selectedProvince: { name: 'West - Vlaanderen' },
    selectedCountry: { name: 'België' },
    selectedEventType: '',
    customErrors: {
      discipline: '',
      map: '',
      eventType: '',
    },
    show: false,
    imageObj: '',
    error: false,
    provinceArray: provinceArray.map((p) => ({ name: p })),
    countryArray: countryArray.map((c) => ({ name: c })),
    editorToolbar: [['bold', 'link']],
    existingMarker: {},
    loadCoordinateMap: false,
    url: process.env.VUE_APP_URL_MANUAL,
    questions: [{ label: 'Vraag 1', question: '', isRequired: false }, { label: 'Vraag 2', question: '', isRequired: false }, { label: 'Vraag 3', question: '', isRequired: false }],
  }),
  computed: {
    ...mapGetters('tag', {
      tagsList: 'getTagList',
    }),
    ...mapGetters('event', {
      existingEvent: 'getEvent',
      eventTypes: 'getEventTypes',
    }),
    ...mapGetters('club', {
      club: 'getClub',
    }),
    ...mapGetters('discipline', {
      disciplineList: 'getDisciplineList',
    }),
    filteredItems() {
      return this.tagsList.filter(
        (i) => i.text.toLowerCase().indexOf(this.currentTag.toLowerCase()) !== -1,
      );
    },
    age: {
      get() {
        return [this.event.fromAge, this.event.toAge];
      },
      set(ageSliderValue) {
        [this.event.fromAge, this.event.toAge] = ageSliderValue;
      },
    },
    formattedDisciplineList() {
      const formattedDisciplineList = [];

      this.disciplineList.forEach((discipline) => {
        const d = _.clone(discipline);
        d.name = `${'- '.repeat(discipline.level)}${discipline.name}`;
        formattedDisciplineList.push(d);
      });
      return formattedDisciplineList;
    },
    infoEmail() {
      return process.env.VUE_APP_INFO_EMAIL_ADDRESS;
    },
  },
  methods: {
    ...mapActions('tag', {
      initialiseTags: 'FETCH_DATA_TAG_LIST',
    }),
    ...mapActions('event', {
      initialiseEvent: 'FETCH_DATA_EDIT_EVENT',
      initialiseEventTypes: 'FETCH_DATA_EVENT_TYPES',
      createNewEventAction: 'CREATE_NEW_EVENT',
      updateEventAction: 'UPDATE_EVENT',
    }),
    ...mapActions('club', {
      initialiseClub: 'FETCH_DATA_CLUB',
    }),
    ...mapActions('discipline', {
      initialiseDisciplineList: 'FETCH_DATA_DISCIPLINE_LIST',
    }),
    cropSuccess(imgDataUrl) {
      fetch(imgDataUrl)
        .then((res) => res.blob())
        .then((blob) => {
          const file = new File([blob], 'File name', { type: 'image/png' });
          this.event.image = file;
          this.event.hasPicture = true;
        })
        .then(() => {
          this.createImage(this.event.image);
          this.toggleShow();
        });
    },
    createImage(file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.imageObj = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    toggleShow() {
      this.show = !this.show;
    },
    save() {
      this.$refs.form.validate().then((success) => {
        if (!success) {
          setTimeout(() => {
            const errorKeys = Object.keys(this.$refs.form.errors);
            const errors = errorKeys.filter((e) => this.$refs.form.errors[e].length > 0);

            this.$refs.form.refs[errors[0]].$el.scrollIntoView();
            window.scrollBy(0, -75);
          }, 100);
        } else {
          this.event.questions = this.questions;
          if (isNullOrWhiteSpace(this.club.address_Country) || isNullOrWhiteSpace(this.club.address_Province) || isNullOrWhiteSpace(this.event.eventTypeId)) {
            this.event.address_Province = this.selectedProvince ? this.selectedProvince.name : '';
            this.event.address_Country = this.selectedCountry.name;
            this.event.eventTypeId = this.selectedEventType.id;
          }

          if (typeof (this.selectedEventType) === 'string' && isNullOrWhiteSpace(this.selectedEventType)) {
            this.customErrors.eventType = 'Dit veld is vereist.';

            this.$refs.eventType.scrollIntoView();
            window.scrollBy(0, -75);
          } else if (this.event.lat == null) {
            this.customErrors.map = 'Gelieve een locatie aan te duiden op de map.';

            this.$refs.map.scrollIntoView();
            window.scrollBy(0, -75);
          }

          if (this.event.lat != null) {
            this.customErrors.map = '';
          }

          this.event.disciplineIds = this.selectedDisciplines.map((discipline) => discipline.id);

          if (typeof (this.selectedEventType) !== 'string') {
            this.customErrors.eventType = '';
          }

          if (!isNullOrWhiteSpace(this.customErrors.discipline)
            || !isNullOrWhiteSpace(this.customErrors.map)
            || !isNullOrWhiteSpace(this.customErrors.eventType)) {
            return;
          }

          if (this.editMode) {
            this.updateEvent();
          } else {
            this.createNewEvent();
          }
        }
      });
    },
    createNewEvent() {
      this.createNewEventAction(this.event)
        .then(() => {
          this.$router.push({ name: 'event-detail', params: { eventurl: this.event.eventUrl } });
        })
        .catch((error) => {
          this.$toasted.show(...error.response.data);
        });
    },
    updateEvent() {
      this.updateEventAction(this.event)
        .then(() => {
          this.$router.push({ name: 'event-detail', params: { eventurl: this.event.eventUrl } });
        })
        .catch((error) => {
          this.$toasted.show(...error.response.data);
        });
    },
    isAdmin() {
      return !!(this.$isAdmin || this.club.currentUserIsAdmin);
    },
    cancel() {
      if (this.event.id === undefined) {
        this.$router.push({ name: 'events' });
      } else {
        this.$router.push({ name: 'event-detail', params: { eventurl: this.event.eventUrl } });
      }
    },
    tagsChanged(tags) {
      this.tags = tags.map((t) => ({ text: t.text }));
      this.event.tags = this.tags;
    },
    updateProvinceId(selectedProvince) {
      if (selectedProvince) {
        this.event.address_Province = selectedProvince.name;
      }
    },
    setEventUrl() {
      if (!this.event.eventUrl) {
        this.$set(this.event, 'eventUrl', this.event.name.replace(/ /g, '_'));
      }
    },
    updateCountryId(selectedCountry) {
      if (selectedCountry) {
        this.event.address_Country = selectedCountry.name;
        if (selectedCountry.name !== 'België') {
          this.event.address_Province = null;
          this.selectedProvince = null;
        }
      }
    },
    UpdateTypeId(selectedEventType) {
      if (selectedEventType) {
        this.customErrors.eventType = '';
        this.event.eventTypeId = selectedEventType.id;
      }
    },
    newLatLong(newLatLong) {
      this.customErrors.map = '';
      [this.event.lat, this.event.long] = newLatLong;
    },
    updateUntilDate() {
      this.event.until = this.event.from;
    },
    clearRegistrationPossibleUntil() {
      console.log('clearRegistrationPossibleUntil');
      this.event.registrationPossibleUntil = null;
    },
  },
  async created() {
    await this.initialiseTags();
    await this.initialiseEventTypes();
    await this.initialiseDisciplineList();

    // Only when editing an existing Event
    if (this.$route.params.eventurl) {
      this.editMode = true;
      if (this.$route.params.duplicate) {
        this.editMode = false;
      }
      this.eventUrl = this.$route.params.eventurl;
      this.initialiseEvent(this.eventUrl).then(() => {
        if (this.existingEvent.club.id) {
          this.clubId = this.existingEvent.club.id;
          this.clubUrl = this.existingEvent.club.clubUrl;
          this.initialiseClub(this.clubUrl).then(() => {
            this.event.clubId = this.clubId; // Set clubId for event object
            this.event.clubUrl = this.clubUrl;
            if (!this.isAdmin()) {
              this.$router.push({ name: '404' });
            }
          });
        }
        // This is a dirty way to clone data from a store mutation without it being tracked
        this.event = _.clone(this.existingEvent);
        this.imageObj = _.cloneDeep(this.existingEvent.profilePicture);
        this.event.clubId = this.clubId; // Set clubId for event object
        this.selectedProvince.name = this.event.address_Province;
        this.selectedCountry.name = this.event.address_Country;

        this.selectedCountry.name = this.event.address_Country;
        this.selectedProvince.name = this.event.address_Province;
        this.selectedEventType = this.event.eventType;
        this.existingMarker = {
          id: 1,
          latLong: [this.event.lat, this.event.long],
        };

        this.event.questions.forEach((question, index) => {
          this.questions[index].question = question.question;
          this.questions[index].isRequired = question.isRequired;
        });

        // Set existing tags:
        this.tags = this.event.eventTags.map((t) => ({ text: t.name }));
        this.event.tags = this.tags; // Update/Create model requires this format.

        // set default multiselect value:
        this.initialiseDisciplineList().then(() => {
          this.selectedDisciplines = this.disciplineList.filter((x) => this.event.disciplineIds.includes(x.id));
        });

        this.loadCoordinateMap = true;
      });
    } else {
      this.clubUrl = this.$route.params.cluburl;
      this.event.clubUrl = this.clubUrl;
    }
  },
};
